import React from "react"
import Layout from "../components/share/layout"
import Arteycultura from "../components/arteycultura/";
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../components/share/footer"

const ArteyCultura = () => {
  const { allContentfulArteYCultura: { nodes: nodesBlog }, allContentfulGeneral: { nodes: nodesInfo } } = useStaticQuery(
    graphql`
      query {

        allContentfulArteYCultura(filter: {node_locale: {eq: "en-US"}, categorylink: {in: "4"}}, sort: {fields: fechaDeOrden, order: DESC}) {
            nodes {
              id
              titulo
              tituloDeRespaldo
              descripcion
              fecha
              enExhibicion
              imagenDeLaEntrada {
                file {
                  fileName
                  url
                }
                fluid (quality: 80,maxHeight: 1534){
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
                description
              }
            }
          }
          allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}) {
            nodes {
              blogSubtitulo
            }
          }
        }
    `)


  return (
    <Layout
      title="Creación Literaria | Arte y Cultura | UPC Cultural"
      metaTitle="Creación Literaria | Arte y Cultura | UPC Cultural"
      description="Aterriza en el lugar de la Creación Literaria en Arte y Cultura de UPC Cultural de la Universidad Peruana de Ciencias Aplicadas. "
      subtitile="Aterriza en el lugar de la Creación Literaria en Arte y Cultura de UPC Cultural de la Universidad Peruana de Ciencias Aplicadas. "
      metaDescripcin="Aterriza en el lugar de la Creación Literaria en Arte y Cultura de UPC Cultural de la Universidad Peruana de Ciencias Aplicadas. "
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="creacion literaria upc cultural, creacion literaria arte y cultura upc"
    >
      <Arteycultura
        nodesBlog={nodesBlog}
        nodesInfo={nodesInfo}
      />
      <Footer />
    </Layout>
  )
}
export default ArteyCultura

